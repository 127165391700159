.list-category-wrapper {
  .ant-card {
    //margin: 30px 95px;
    background: none;
    .ant-card-body {
      padding: 24px 25px;
    }
  }

  .ant-table-filter-trigger-container {
    //background-color: #f1f1f1 !important;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;

    .add-new {
      height: 35px;
      background: #ff8c1a 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      border: none;

      span {
        text-align: left;
        font: normal normal 400 15px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .table-height {
    th {
      border: none !important;
    }

    tr {
      // height: 50px;
      padding: 0;
      margin: 0;
      // border: none !important;

      td {
        // border: none !important;
        border-top: none !important;
        border-right: none !important;
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
  }

  .ant-table-content {
    padding: 0 20px;

    table {
      background: #ffffff;
      border-spacing: 0px 5px;

      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;

          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;

      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;

        a {
          color: #ffffff;
        }
      }
    }
  }
}

.edit-category-wrapper {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;

    .long-term-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      border: none;

      &.save {
        background: #ff8c1a;
      }
      &:disabled {
        background: grey;
      }

      span {
        text-align: left;
        font: normal normal 400 18px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .ant-col {
    padding: 8px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .ant-form-item {
    margin-bottom: 3px;
  }

  .ant-form-item-label {
    padding: 2px !important;
  }

  .ant-form-item-control {
    padding: 2px !important;
  }

  .table-height {
    .ant-table {
      min-height: 280px;
    }

    tr td {
      padding: 10px 10px;
    }
  }
}
