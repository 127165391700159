.companyProfile-wrapper {
  padding: 10px !important;

  .card {
    margin-bottom: 10px;
  }

  .avatar {
    width: 120px !important;
    height: 120px !important;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    .add-button {
      // width: 140px;
      height: 35px;
      border-radius: 10px;
      border: none;
      background: #ff8c1a;
      span {
        text-align: left;
        font: normal normal 400 15px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .center {
    text-align: center !important;
  }

  .gutter-row-mission {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
  }

  .gutter-row-vision {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 10px;
  }
  .gutter-row-values {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 10px;
  }

  .table-height {
    tr {
      padding: 0;
      margin: 0;

      td {
        border: none;
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          background: transparent;
          border: none;
          color: grey;
        }
      }

      .ant-pagination-item-link {
        background: transparent;
        border: none;
        color: #023b72;
      }
    }
  }

  .ant-table-content {
    padding: 0 20px;

    table {
      background: #ffffff;
      border-spacing: 0px 5px;

      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;

          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;

      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;

        a {
          color: #ffffff;
        }
      }
    }
  }
}
