.sub450-card-body {
  .ant-card-body {
    height: 450px;
    overflow-x: auto;
  }
}

.long-card {
  .ant-card-head-wrapper {
    height: 46px;
  }

  .ant-card-head-title {
    font-size: 17px;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      padding: 6px 0;
      font-size: 16px !important;
    }
  }
}

.cus-card {
  .ant-card-head-wrapper {
    height: 46px;
  }

  .ant-card-head-title {
    font-size: 17px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 2px 0;
      font-size: 16px !important;
    }
  }
}

.cus-collapse-title {
  .ant-collapse-header-text {
    font-size: 17px;
    color: #023b72;
    font-weight: 500;
  }
}

.active-obietivi-row {
  td {
    background: #dee1e5 !important;
  }
}

.obietivo-anno-wrapper {
  .ant-card {
    background: transparent;
  }
  .ant-table-filter-trigger-container {
    //background-color: #f1f1f1 !important;
  }

  .button-wrapper-anual {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;
  }

  .table-height {
    tr {
      // height: 50px;
      padding: 0;
      margin: 0;

      td {
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
  }

  .activities-header {
    background: transparent;
    margin-right: 140px;
    font: 24px SF Pro Display;
    padding: 29px 0 20px 10px;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font: normal normal 400 17px SF Pro Display;
        padding-top: 29px !important;
      }
    }
  }

  .zoomSecHeight {
    height: 85vh !important;
    overflow: auto !important;

    .bf-container {
      height: 85vh !important;
      max-height: 100%;
    }

    .bf-content {
      height: 100%;
    }
  }

  .activities-filters {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;

    .filters {
      .date-filters {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;

        .activities-buttons {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          span {
            .ant-btn:disabled,
            .ant-btn,
            button {
              border: none;
            }

            color: #7c7c7c;

            svg {
              height: 25px;
              width: 25px;
            }
          }

          .clear {
            border-color: transparent;
            background: transparent;
            color: #7c7c7c;
            margin-bottom: 5px;
          }
          .ant-form-item {
            .ant-form-item {
              &.main-container {
                display: flex;
                flex-direction: column;

                .ant-form-item-label {
                  label {
                    float: left;
                  }
                }
              }
            }
          }
        }

        .ant-picker {
          background: transparent;
          border: 1px solid #909090;
          border-radius: 4px;
          color: #909090;
          height: 35px;
        }
      }
    }

    .add-activities {
      height: 35px;
      background: #ff8c1a 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      border: none;
      margin-left: 25px;

      span {
        text-align: left;
        font: normal normal 400 15px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
    .activities-toggle {
      display: flex;
      flex-direction: row;
      .ant-form-item-label {
        label {
          color: #989898;
          font: normal normal 16px SF Pro Display;
          float: left;
        }
      }

      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          color: #989898;
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number,
            .ant-picker,
            .ant-input,
            .ant-select-selector {
              border: 1px solid #d9d9d9;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      background: #f2f2f2;

      td {
        border: none;

        span {
          &.anticon {
            &.anticon-edit {
              svg {
                color: #023b72;
              }
            }
            &.anticon-delete {
              svg {
                color: #c3c3c3;
              }
            }
          }
        }
      }
    }
  }
  .ant-table-content {
    padding: 0 20px;
    table {
      background: #ffffff;
      border-spacing: 0px 5px;
      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;
          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;
      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;
        a {
          color: #ffffff;
        }
      }
    }
  }
}

// .edit-obietivo-anno-wrapper {
//   .button-wrapper {
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     margin-bottom: 10px;

//     .long-term-button {
//       width: 160px;
//       height: 45px;
//       border-radius: 10px;
//       border: none;

//       &.save {
//         background: #ff8c1a;
//       }

//       &.remove {
//         background: #9f9f9f;
//       }

//       span {
//         text-align: left;
//         font: normal normal 400 18px SF Pro Display;
//         letter-spacing: 0px;
//         color: #ffffff;
//         opacity: 1;
//       }
//       &:disabled {
//         background: rgb(211, 211, 211);
//       }
//     }
//   }

//   .ant-form {
//     .main-container {
//       display: flex;
//       flex-direction: column;

//       .ant-form-item-label {
//         label {
//           float: left;
//         }
//       }

//       .ant-form-item-control {
//         .ant-select-selector,
//         .ant-input,
//         .ant-input-number-input-wrap {
//           min-height: 32px;
//           align-items: center;
//         }

//         .ant-form-item-control-input {
//           .ant-form-item-control-input-content {
//             .ant-input-number,
//             .ant-picker,
//             .ant-input,
//             .ant-select-selector {
//               width: 100%;
//             }
//           }
//         }
//       }
//     }
//   }

//   .create-kpi {
//     background: #ff8c1a;
//     border: none;
//     margin-right: 35px;

//     span {
//       color: #ffffff;
//     }
//   }

//   .table-height {
//     tr {
//       padding: 0;
//       margin: 0;

//       td {
//         border: none;
//         padding: 9px 25px;
//         color: #323232;
//         background: #f2f2f2;
//         font: normal normal 500 14px SF Pro Display;
//       }
//     }

//     .ant-pagination-prev,
//     .ant-pagination-next {
//       &.ant-pagination-disabled {
//         .ant-pagination-item-link {
//           background: transparent;
//           border: none;
//           color: grey;
//         }
//       }

//       .ant-pagination-item-link {
//         background: transparent;
//         border: none;
//         color: #023b72;
//       }
//     }
//   }

//   .ant-table-content {
//     padding: 0 20px;

//     table {
//       background: #ffffff;
//       border-spacing: 0px 5px;

//       .ant-table-thead {
//         th {
//           color: #5b5b5b;
//           background: #ffffff !important;
//           font: normal normal 800 15px SF Pro Display;
//           border-bottom: none;

//           &.ant-table-column-has-sorters:hover,
//           &.ant-table-column-sort {
//             background: #ffffff;
//           }
//         }
//       }
//     }
//   }

//   .ant-pagination {
//     .ant-pagination-item {
//       border: 1px solid #023b72;
//       border-radius: 4px;

//       a {
//         color: #023b72;
//       }

//       &.ant-pagination-item-active {
//         background: #023b72 0% 0% no-repeat padding-box;
//         border-radius: 4px;
//         border: none;

//         a {
//           color: #ffffff;
//         }
//       }
//     }
//   }
//   .ant-pagination {
//     .ant-pagination-item {
//       border: 1px solid #023b72;
//       border-radius: 4px;

//       a {
//         color: #023b72;
//       }

//       &.ant-pagination-item-active {
//         background: #023b72 0% 0% no-repeat padding-box;
//         border-radius: 4px;
//         border: none;

//         a {
//           color: #ffffff;
//         }
//       }
//     }
//   }
// }
