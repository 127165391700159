.spinner2 {
  position: relative;
  overflow: hidden;
  display: block;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  //background-color: #f5f5f5b8;
}

.spinner2 > div {
  margin-top: 10px;
}

.loading {
  font-family: monospace;
  font-size: 15px;
  color: #a1aeae;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
