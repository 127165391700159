.dash-kpi-wrapper {
  .ant-col {
    padding: 8px;
  }

  .mt20 {
    margin-top: 20px;
  }
}

.kpiSintesi {
  .project-filters-kpiDash {
    grid-column-gap: 35px;
    column-gap: 0px;
    align-items: center;
    z-index: 88;
    top: 125px;
    position: absolute;
    //right: 35px;

    .ant-btn-dashed,
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus,
    .ant-btn-dashed:active {
      background: transparent !important;
      border-color: transparent !important;
      border-style: none !important;
    }

    .ant-picker,
    .ant-select-selector,
    .ant-select {
      height: 34px !important;
      // width: 273px;
      border-color: #909090 !important;
      border-radius: 4px !important;
      .ant-select-selection-item {
        line-height: 34px !important;
      }
      .ant-select-selection-placeholder {
        line-height: 30px !important;
      }
    }
  }

  .das-header {
    .ant-typography {
      color: #023b72;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .kpiInfoDivider {
    height: 1.2em;
    margin: 0 3px;
    border-left: 1px solid #afafaf;
  }

  .kpiBC-table-wrapper {
    background: #eaeaea;
    padding: 0px 0px;

    .table {
      border-color: #f2f2f2 !important;
      border-collapse: collapse;
    }

    .title {
      color: #023b72;
      font-size: 19px;
      padding: 10px 0;
      margin-bottom: 25px;
      display: block;
      border-bottom: 1px solid #ccc;
    }

    .ant-table-thead {
      th {
        height: 60px;
        background: #cfdce8;
        font-size: 14px;
        text-align: center;
        color: #2f4897 !important;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        a {
          color: #2f4897 !important;
        }
        &.total {
          background: #f2f2f2 !important;
          text-align: center;
        }
        &.operation {
          background: #f2f2f2 !important;
          text-align: center !important;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td:last-child {
          text-align: center;
          padding: 2px;
        }
      }
      td > span {
        color: #2f4897 !important;
      }
      .ant-table-cell {
        &.total {
          border: none;
          background: #f2f2f2 !important;
          text-align: center;
        }
        &.operation {
          text-align: center;
          border: none;
          background: #f2f2f2 !important;
        }
        &.project {
          padding-left: 15px;
          a {
            text-transform: uppercase;
            font-size: 16px;
          }
        }
        .projectData {
          font-size: 12px;
          .ant-typography {
            color: #2f4897 !important;
            margin-left: 4px;
          }
        }
        .projectName {
          text-transform: uppercase;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-empty-description {
        color: #2f4897 !important;
        font-size: 14px;
      }

      .ant-input-number {
        width: 100% !important;
        border: 1px solid #dcdcdc;
      }
      .ant-input-number-input {
        height: 25px;
      }
    }
  }

  .monthPicker {
    .ant-picker-input input {
      text-transform: capitalize;
    }
  }

  .kpiSemaphore {
    .green {
      background-color: #6abf6a;
    }

    .red {
      background-color: #dc2626;
    }

    .neutral {
      background-color: #bdbdbd;
    }
  }

  .kpiSintesiTable {
    background: #ffffff;
    margin-top: 160px;
    padding: 25px 12px;

    .table {
      border-color: #f2f2f2 !important;
      border-collapse: collapse;
    }

    .title {
      color: #023b72;
      font-size: 19px;
      padding: 10px 0;
      margin-bottom: 25px;
      display: block;
      border-bottom: 1px solid #ccc;
    }

    .ant-table-thead {
      th {
        height: 50px;
        background: #cfdce8 !important;
        font-size: 14px !important;
        text-align: center;
        color: #2f4897 !important;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        a {
          color: #2f4897 !important;
        }
        &.total {
          background: #f2f2f2 !important;
          text-align: center;
        }
        &.operation {
          background: #f2f2f2 !important;
          text-align: center !important;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td:last-child {
          text-align: center;
          padding: 2px;
        }
      }
      td > span {
        color: #2d2b2b !important;
      }
      .ant-table-cell {
        &.total {
          border: none;
          background: #f2f2f2 !important;
          text-align: center;
        }
        &.operation {
          text-align: center;
          border: none;
          background: #f2f2f2 !important;
        }
        &.project {
          padding-left: 15px;
          a {
            text-transform: uppercase;
            font-size: 16px;
          }
        }
        .projectData {
          font-size: 12px;
          .ant-typography {
            color: #2f4897 !important;
            margin-left: 4px;
          }
        }
        .projectName {
          text-transform: uppercase;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-empty-description {
        color: #2f4897 !important;
        font-size: 14px;
      }

      .ant-input-number {
        width: 100% !important;
        border: 1px solid #dcdcdc;
      }
      .ant-input-number-input {
        height: 25px;
      }
    }
  }
}

.bf-content {
  height: 380px;
}

.sub460-delChart-card-body {
  padding: 18px;
  border-radius: 6px;

  .ant-card-head {
    min-height: auto;

    .ant-card-head-wrapper {
      height: 45px;
      align-items: unset;
      margin-bottom: 15px;
      border-bottom: 1px solid #b8b8b8;

      .ant-card-head-title {
        padding: 0;
        color: #002255;
        font: normal normal 16px SF Pro Display;
      }

      .ant-card-extra {
        span {
          svg {
            color: #b8b8b8;
            width: 19px;
            height: 19px;
          }
        }

        .ant-btn {
          border: none;
        }
      }
    }
  }

  .ant-card-body {
    height: 400px;
    overflow-x: auto;
    //padding: 15px;
    border-radius: 6px;
    background-color: white;

    .form-container {
      display: flex;
      flex-direction: column;

      .title {
        color: #323232;
        font: normal normal 16px SF Pro Display;
      }

      .content {
        color: #012c60;
        font: 16px SF Pro Display;
        font-weight: 600;
        margin-top: 13px;
      }

      &.date {
        align-self: center;
        align-items: center;
        align-content: center;
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      margin-top: -10px;

      .ant-form-item-label {
        label {
          font-size: 14px;
          float: left;
        }
      }

      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .ant-col-9 {
        flex: none;
        max-width: none;
      }

      &.projectCode {
        .ant-col-15 {
          flex: none;
          max-width: 82%;
        }
      }
    }
  }
}

.sub460-delChart-card-body {
  .ant-card-body {
    height: 460px !important;
    overflow: auto !important;
  }
}

.sub500-delChart-card-body {
  padding: 18px;
  border-radius: 6px;

  .ant-card-head {
    min-height: auto;

    .ant-card-head-wrapper {
      height: 45px;
      align-items: unset;
      margin-bottom: 15px;
      border-bottom: 1px solid #b8b8b8;

      .ant-card-head-title {
        padding: 0;
        color: #002255;
        font: normal normal 16px SF Pro Display;
      }

      .ant-card-extra {
        span {
          svg {
            color: #b8b8b8;
            width: 19px;
            height: 19px;
          }
        }

        .ant-btn {
          border: none;
        }
      }
    }
  }

  .ant-card-body {
    height: 400px;
    overflow-x: auto;
    //padding: 15px;
    border-radius: 6px;
    background-color: white;

    .form-container {
      display: flex;
      flex-direction: column;

      .title {
        color: #323232;
        font: normal normal 16px SF Pro Display;
      }

      .content {
        color: #012c60;
        font: 16px SF Pro Display;
        font-weight: 600;
        margin-top: 13px;
      }

      &.date {
        align-self: center;
        align-items: center;
        align-content: center;
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      margin-top: -10px;

      .ant-form-item-label {
        label {
          font-size: 14px;
          float: left;
        }
      }

      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .ant-col-9 {
        flex: none;
        max-width: none;
      }

      &.projectCode {
        .ant-col-15 {
          flex: none;
          max-width: 82%;
        }
      }
    }
  }
}

.sub500-delChart-card-body {
  .ant-card-body {
    height: 500px !important;
    overflow: auto !important;
  }
}

.bowlingChart-card-body {
  padding: 18px;
  border-radius: 6px;

  .ant-card-head {
    min-height: auto;

    .ant-card-head-wrapper {
      height: 45px;
      align-items: unset;
      margin-bottom: 15px;
      border-bottom: 1px solid #b8b8b8;

      .ant-card-head-title {
        padding: 0;
        color: #002255;
        font: normal normal 16px SF Pro Display;
      }

      .ant-card-extra {
        span {
          svg {
            color: #b8b8b8;
            width: 19px;
            height: 19px;
          }
        }

        .ant-btn {
          border: none;
        }
      }
    }
  }

  .ant-card-body {
    // height: 400px;
    // overflow-x: auto;
    //padding: 15px;
    border-radius: 6px;
    background-color: white;

    .form-container {
      display: flex;
      flex-direction: column;

      .title {
        color: #323232;
        font: normal normal 16px SF Pro Display;
      }

      .content {
        color: #012c60;
        font: 16px SF Pro Display;
        font-weight: 600;
        margin-top: 13px;
      }

      &.date {
        align-self: center;
        align-items: center;
        align-content: center;
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      margin-top: -10px;

      .ant-form-item-label {
        label {
          font-size: 14px;
          float: left;
        }
      }

      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .ant-col-9 {
        flex: none;
        max-width: none;
      }

      &.projectCode {
        .ant-col-15 {
          flex: none;
          max-width: 82%;
        }
      }
    }
  }
}

.sub300-delChart-card-body {
  padding: 18px;
  border-radius: 6px;

  .ant-card-head {
    min-height: auto;

    .ant-card-head-wrapper {
      height: 45px;
      align-items: unset;
      margin-bottom: 15px;
      border-bottom: 1px solid #b8b8b8;

      .ant-card-head-title {
        padding: 0;
        color: #002255;
        font: normal normal 16px SF Pro Display;
      }

      .ant-card-extra {
        span {
          svg {
            color: #b8b8b8;
            width: 19px;
            height: 19px;
          }
        }

        .ant-btn {
          border: none;
        }
      }
    }
  }

  .ant-card-body {
    height: 300px;
    overflow-x: auto;
    //padding: 15px;
    border-radius: 6px;
    background-color: white;

    .form-container {
      display: flex;
      flex-direction: column;

      .title {
        color: #323232;
        font: normal normal 16px SF Pro Display;
      }

      .content {
        color: #012c60;
        font: 16px SF Pro Display;
        font-weight: 600;
        margin-top: 13px;
      }

      &.date {
        align-self: center;
        align-items: center;
        align-content: center;
      }
    }

    .main-container {
      display: flex;
      flex-direction: column;
      margin-top: -10px;

      .ant-form-item-label {
        label {
          font-size: 14px;
          float: left;
        }
      }

      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number {
              width: 100%;
            }
          }
        }
      }

      .ant-col-9 {
        flex: none;
        max-width: none;
      }

      &.projectCode {
        .ant-col-15 {
          flex: none;
          max-width: 82%;
        }
      }
    }
  }
}

.sub300-delChart-card-body {
  .ant-card-body {
    height: 300px !important;
    overflow: auto !important;
  }
}
