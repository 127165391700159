// .colNameBB{
//   background: #f2f2f2 !important;
// }

// .colNameBBD{
//   border-bottom: 1px solid #f2f2f2 !important;
// }

.colNameBB {
  border-bottom: 4px solid #2f4897 !important;
  background: #f2f2f2 !important;
}

.colNameBBD {
  border-bottom: 4px solid #2f4897 !important;
}

.project-time-planning-wrapper {
  padding: 10px;

  .project-filters {
    display: flex;
    justify-content: flex-end;
    column-gap: 35px;
    padding: 0 60px;
    align-items: center;

    .ant-btn-dashed,
    .ant-btn-dashed:hover,
    .ant-btn-dashed:focus,
    .ant-btn-dashed:active {
      background: transparent !important;
      border-color: transparent !important;
      border-style: none !important;
    }

    .ant-picker,
    .ant-select-selector {
      height: 34px !important;
      width: 273px !important;
      border-color: #909090 !important;
      border-radius: 4px !important;
      .ant-select-selection-item {
        line-height: 34px !important;
      }
      .ant-select-selection-placeholder {
        line-height: 30px !important;
      }
    }
  }

  .project-table-wrapper {
    background: #ffffff;
    padding: 20px 30px;

    .table {
      border-color: #f2f2f2 !important;
      border-collapse: collapse;
    }

    .title {
      color: #023b72;
      font-size: 19px;
      padding: 10px 0;
      margin-bottom: 25px;
      display: block;
      border-bottom: 1px solid #ccc;
    }
    .ant-table-thead {
      th {
        height: 87px;
        background: #cfdce8 !important;
        font-size: 14px !important;
        text-align: center;
        color: #2f4897 !important;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        a {
          color: #2f4897 !important;
        }
        &.total {
          background: #f2f2f2 !important;
          text-align: center;
        }
        &.operation {
          background: #f2f2f2 !important;
          text-align: center !important;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td:last-child {
          text-align: center;
          padding: 2px;
        }
      }
      td > span {
        color: #2f4897;
      }
      .ant-table-cell {
        &.total {
          border: none;
          background: #f2f2f2;
          text-align: center;
        }
        &.operation {
          text-align: center;
          border: none;
          background: #f2f2f2 !important;
        }
        &.project {
          padding-left: 15px;
          a {
            text-transform: uppercase;
            font-size: 16px;
          }
        }
        .projectData {
          font-size: 12px;
          .ant-typography {
            color: #2f4897 !important;
            margin-left: 4px;
          }
        }
        .projectName {
          text-transform: uppercase;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .ant-empty-description {
        color: #2f4897 !important;
        font-size: 14px;
      }

      .ant-input-number {
        width: 100% !important;
        border: 1px solid #dcdcdc;
      }
      .ant-input-number-input {
        height: 25px;
      }
    }
  }

  /*    th {
      text-align: center !important;
    }
  
    .table-row-project{
      background-color:#e6e6e6;
    }
  
    .ant-input-number {
      width: 100% !important;
      border: 1px solid #dcdcdc;
    }
  
    .ant-input-number-input {
      height: 25px;
    }
  
    .mr10{
      margin-right: 10px;
    }
  
    .mb10{
      margin-bottom: 10px;
  }
  .mt10{
    margin-top: 10px;
  }
  
  .button-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-bottom: 1px dashed #cfcfcf;
      margin-bottom: 10px;
    
      .ant-form-item {
          margin-bottom: 10px;
        }
    
    }*/
}
