.project-resource-wrapper {
  .ml {
    margin-left: 10px;
  }

  .table-row-project {
    background-color: #e6e6e6;
  }

  .table-row-user-project {
    background-color: #fafbe9;
  }

  .negative-color {
    color: '#f92f2f';
  }

  .ant-input-number {
    width: 100% !important;
    border: 1px solid #dcdcdc;
  }

  .ant-input-number-input {
    height: 25px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}

.project-information {
  font-size: 18px;
  padding: 25px 35px;
  border-bottom: 1px solid #ccc;
  background: white;
  span {
    color: #023b72;
  }
}
.project-table-wrapper {
  background: #ffffff;
  padding: 20px 30px;

  .table {
    border-color: #f2f2f2 !important;
    border-collapse: collapse;
  }

  .title {
    color: #023b72;
    font-size: 19px;
    padding: 10px 0;
    margin-bottom: 25px;
    display: block;
    border-bottom: 1px solid #ccc;
  }

  .ant-table-thead {
    th {
      height: 45px;
      background: #cfdce8 !important;
      font-size: 14px !important;
      text-align: center;
      color: #2f4897 !important;
      text-transform: uppercase;
      letter-spacing: -0.5px;
      a {
        color: #2f4897 !important;
      }
      &.total {
        background: #f2f2f2 !important;
        text-align: center;
      }
      &.operation {
        background: #f2f2f2 !important;
        text-align: center !important;
      }
    }
  }
  .ant-table-tbody {
    tr {
      td:last-child {
        text-align: center;
        padding: 2px;
      }
    }
    td > span {
      color: #2f4897;
    }
    .ant-table-cell {
      &.total {
        border: none;
        background: #f2f2f2;
        text-align: center;
      }
      &.operation {
        text-align: center;
        border: none;
        background: #f2f2f2 !important;
      }
      &.project {
        padding-left: 15px;
        a {
          text-transform: uppercase;
          font-size: 16px;
        }
      }
      .projectData {
        font-size: 12px;
        .ant-typography {
          color: #2f4897 !important;
          margin-left: 4px;
        }
      }
      .projectName {
        text-transform: uppercase;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-empty-description {
      color: #2f4897 !important;
      font-size: 14px;
    }

    .ant-input-number {
      width: 100% !important;
      border: 1px solid #dcdcdc;
    }
    .ant-input-number-input {
      height: 25px;
    }
  }
}
