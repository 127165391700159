.commitie-wrapper {
  .ant-card {
    //margin: 30px 95px;
    background: none;
    .ant-card-body {
      padding: 24px 85px;
    }
  }

  .center-text {
    text-align: center;
  }

  .ant-table-filter-trigger-container {
    //background-color: #f1f1f1 !important;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    .kpi-btn {
      height: 35px;
      background: #ff8c1a 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      border: none;
      span {
        text-align: left;
        font: normal normal 400 15px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .table-height {
    tr {
      // height: 50px;
      padding: 0;
      margin: 0;

      td {
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
  }
  .ant-table-content {
    padding: 0 20px;
    table {
      background: #ffffff;
      border-spacing: 0px 5px;
      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;
          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;
      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;
        a {
          color: #ffffff;
        }
      }
    }
  }
}

.user-badge-comitie {
  .partecipant {
    border-top: 7px solid #68b97d !important;
  }

  .revisionari {
    border-top: 7px solid #af82be !important;
  }

  .ant-card {
    border-radius: 10px;

    .ant-card-body {
      height: 85px;
      overflow: hidden;
    }

    .ant-card-meta-detail {
      .ant-card-meta-title {
        span {
          color: #012c60;
        }
      }

      .ant-card-meta-description {
        color: #989898;
        font: normal normal 16px SF Pro Display;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    .actions {
      padding: 12px 43px;
      display: flex;
      flex-direction: row;
      color: #4d4c4c;
    }
  }
}

.comitieActivities {
  .ant-card-body {
    padding: 0px;
  }
}

.agendaCards {
  .ant-card-head-title {
    padding: 0px 0;
    font-size: 18px;
  }
}

.agendaTable {
  .ant-table-content > table > tbody > tr > td {
    border: 1px solid #d9d9d9 !important;
    padding: 5px 5px !important;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
  }
}

.comTabs {
  .ant-tabs-content-holder {
    padding: 0px 10px 0px 10px;
  }

  .ant-tabs-extra-content {
    padding: 0px 15px 0px 0px;
    span {
      font-size: 20px;
      color: #ffffff !important;
    }

    .ant-btn {
      span {
        font-size: 16px;
      }
    }
  }
}

.comOverviewCharts {
  .ant-tabs-nav-list {
    border-left: none;
    padding-left: 10px;
  }

  .ant-card-head-title {
    font-size: 16px;
    padding: 6px 0;
  }
}

.edit-commitie-wrapper {
  .expededTable {
    .ant-table {
      margin: 0px !important;
      min-height: unset !important;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
    .long-term-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      border: none;

      &.save {
        background: #ff8c1a;
      }

      &.remove {
        background: #9f9f9f;
      }

      &:disabled {
        background: rgb(211, 211, 211);
      }
      span {
        text-align: left;
        font: normal normal 400 18px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .ant-col {
    padding: 8px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .ant-form-item {
    margin-bottom: 3px;
  }

  .ant-form-item-label {
    padding: 2px !important;
  }

  .ant-form-item-control {
    padding: 2px !important;
  }

  .table-height {
    .ant-table {
      min-height: 280px;
    }
    tr td {
      padding: 10px 10px;
    }
  }
}

.comitieCalendar {
  .tableCal {
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: #e6e9eb;
    }
  }

  .calendarCurrentDayIndicator {
    border-right: 2px solid #8daecd;
    background-color: #d1e8ff;
  }

  .calendarCurrentDayIndicator:hover {
    border-right: 2px solid #8daecd !important;
    background-color: #d1e8ff !important;
  }

  .dot {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    // margin-right: 5px;
    display: inline-block;

    &-green {
      background-color: #6bbf6c;
    }

    &-red {
      background-color: #c94040;
    }

    &-orange {
      background-color: #e8bc4e;
    }

    &-blue {
      background-color: #4d88cb;
    }

    &-empty {
      background-color: #efefef;
      border: 1px solid #cfcfcf;
    }
  }
}

.comitieSintesi {
  .sintesiTable {
    .parentRow {
      td {
        background-color: #e7ebf1;
      }
    }

    .ant-table-row-level-1 {
      td {
        background-color: #f8f8f8;
      }
    }

    .totalCol {
      background: #ebebeb;
    }

    .totalRow {
      td {
        font-weight: 600;
        background: #ebebeb;
      }
    }

    .currentMonthLineIndicatorSintesi {
      border-left: 2px solid #d22323;
    }

    .ant-table-content table {
      border-spacing: 0px 0px;
    }

    .status {
      &-fatto {
        background: #6bbf6c !important;
      }

      &-sospeso {
        background: #c94040 !important;
      }

      &-draft {
        background: #e8bc4e !important;
      }

      &-panificato {
        background: #4d88cb !important;
      }

      &-empty {
        // background: #ffffff !important;
      }

      &-grey {
        background: #dadada !important;
      }

      &-orange {
        background: #ed7d31 !important;
      }
    }
  }
}

.collapse-comitie-activites {
  .ant-collapse {
    border: none;
    background-color: #f2f2f2;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
    overflow: hidden;
    background: #f7f7f7;
  }
}

.row-dragging {
  background: #e2e2e2;
  border: 1px solid #cbcbcb;
  z-index: 1000;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.agendaTransfer {
  .ant-transfer-list {
    width: 50%;
    height: 350px;
  }
}
