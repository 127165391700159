.deliverableLinkButton {
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
}

.edit-deliverable-wrapper {
  .ant-form-item-control {
    padding: 2px !important;
  }
}
