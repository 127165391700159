.resource-wrapper {
  th {
    text-align: left !important;
  }

  .table-row-project {
    background-color: #e6e6e6;
  }

  .bold-bottom-border {
    border-bottom: 2px solid #6f6f6f !important;
  }

  .ant-input-number {
    width: 100% !important;
    border: 1px solid #dcdcdc;
  }

  .ant-input-number-input {
    height: 25px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
