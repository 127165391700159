.ant-menu-overflow-item .ant-menu-title-content {
  color: white;
}

.navbar {
  .ant-menu-horizontal {
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        top: 6px;
      }
    }
  }

  .navbar-button {
    height: 51px;
    transform: translateY(11px);
    display: flex;
    margin-bottom: 11px;
    align-items: center;
  }

  .navbar-button-active {
    position: relative;
    z-index: 2;

    svg {
      fill: #0066ff;
    }

    &::after {
      content: '';
      z-index: -1;
      height: 100%;
      top: 0;
      width: 100%;
      opacity: 1;
      position: absolute;
      left: 0;
      border-radius: 8px 8px 0 0;
      background-color: white;
      border: 0 !important;
      border-width: 0 !important;
    }
  }

  .ant-menu-item,
  .ant-menu-submenu {
    position: relative;
    height: 100%;
    top: 0;

    padding-inline: 16px !important;

    &::after {
      border: 0 !important;
    }
  }

  .ant-menu-item-icon {
    transform: translateY(4px) !important;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    position: relative;
    z-index: 2;

    &:hover {
      &::after {
        opacity: 0.2;
      }
    }

    &::after {
      z-index: -1;
      height: 100%;
      top: 11px;
      width: 100%;
      opacity: 0;
      inset-inline: 0 !important;
      position: absolute;
      left: 0;
      border-radius: 8px 8px 0 0;
      background-color: white;
      border: 0 !important;
      border-width: 0 !important;
    }
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    .ant-menu-title-content {
      color: #003293;
    }

    &::after {
      opacity: 1 !important;
    }
  }
}
