.project-wrapper {
  margin-top: 5px;

  .image-wrap img {
    max-width: 500px;
  }

  .project-details {
    .ant-form-item {
      margin-bottom: 15px;
    }
  }

  .commentCollapse {
    .ant-collapse-header {
      display: block;
    }
  }

  .active-xmatrix {
    color: #15bb23 !important;
  }

  .site-page-header {
    //border-bottom: 1px dashed #cfcfcf;
    padding: 4px 4px;

    .ant-page-header {
    }

    .ant-form-item {
      margin-bottom: 1px;
    }

    .ant-page-header-heading-title {
      font-size: 14px;
      color: #000000;
      line-height: 1.2em;
    }

    .project-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      border: none;

      span {
        text-align: left;
        font: normal normal 400 18px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }

      &.save {
        background: #ff8c1a;
      }

      &:disabled {
        background: grey;
      }

      &.confirm {
        background: #ffffff;

        span {
          color: #000000;
          border: none;
        }
      }
    }

    .ant-form-item-control-input-content {
      display: flex;
    }

    .ant-popover-disabled-compatible-wrapper {
      display: flex;

      &.project-button {
        width: 160px;
        height: 45px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        display: flex !important;
        flex-direction: row;
        margin-left: 20px;
        background: grey;
        &.confirm {
          .ant-btn[disabled] {
            background: #ffffff;
            border: none;

            span {
              color: #000000;
              border: none;
            }
          }
        }

        span {
          text-align: left;
          font: normal normal 400 18px SF Pro Display;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
        .ant-btn[disabled] {
          background: grey;
          border: none;
        }
      }
    }
  }

  // .endDateCheck {
  //   color: #FF1919;
  //   font-size: 16px;
  // }

  .footer-editor-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .greenColor {
    color: #0dcc2d;
  }

  .redColor {
    color: #ff1313;
  }

  .yellowColor {
    color: #fbd311;
  }

  .greyColor {
    color: #797979;
  }

  .blueColor {
    color: #1760ba;
  }

  .semaphore-section {
    display: flex;
    justify-content: space-around;
    @media only screen and (max-width: 575px) {
      justify-content: space-between;
    }

    .green {
      background-color: #38b44c;
    }

    .yellow {
      background-color: #fbda2e;
    }

    .red {
      background-color: #fa2626;
    }

    .ant-avatar-sm {
      vertical-align: middle;
    }

    .semaphoreSpace {
      &.green-color-bgr {
        background-color: #4cbb17;

        .ant-space-item {
          .greyColorIndicator {
            font-size: 20px !important;
            background-color: #4cbb17 !important;
            width: 55px;
            height: 55px;
          }
        }
      }

      &.red-color-bgr {
        background-color: #ff1313;

        .ant-space-item {
          .greyColorIndicator {
            font-size: 20px !important;
            background-color: #ff1313 !important;
            width: 55px;
            height: 55px;
          }
        }
      }

      &.yellow-color-bgr {
        background-color: #fbd311;

        .ant-space-item {
          .greyColorIndicator {
            font-size: 20px !important;
            background-color: #fbd311 !important;
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }

  .no-border {
    border: none;
  }

  .title-items-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;
  }

  .mb20 {
    padding: 4px;
    @media only screen and (max-width: 575px) {
      margin-bottom: 15px;
      border-bottom: 1px solid #dedede;
      border-left: none;
    }
  }

  &.edit-deliverable,
  .deliverable-activities {
    .edit-deliverable-header {
      display: flex;
      background: transparent linear-gradient(267deg, #023b72 0%, #002255 100%) 0% 0% no-repeat padding-box;
      padding-left: 115px;
      padding-right: 89px;
      grid-row-gap: 0px;
      margin: 0;
      height: 90px;
      row-gap: 0px;
      font: 24px SF Pro Display;

      h3 {
        color: #ffffff;
        align-self: center;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .long-term-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      border: none;
      &.save {
        background: #ff8c1a;
        float: right;
      }

      span {
        text-align: left;
        font: normal normal 400 18px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
    .backButton {
      width: 141px;
      height: 45px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 14px;
      opacity: 1;

      span {
        text-align: left;
        font: normal normal 600 16px/19px SF Pro Display;
        color: #012c60;
        opacity: 1;
      }
    }

    .ant-card-body {
      .main-container {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          label {
            font-size: 14px;
            float: left;
          }
        }

        .ant-form-item-control {
          .ant-select-selector,
          .ant-input,
          .ant-input-number-input-wrap {
            min-height: 32px;
            align-items: center;
          }

          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              .ant-input-number,
              .ant-picker,
              .ant-input,
              .ant-select-selector {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .table-height {
      tr {
        padding: 0;
        margin: 0;

        td {
          border: none;
          padding: 9px 25px;
          color: #323232;
          background: #f2f2f2;
          font: normal normal 500 14px SF Pro Display;
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        &.ant-pagination-disabled {
          .ant-pagination-item-link {
            background: transparent;
            border: none;
            color: grey;
          }
        }

        .ant-pagination-item-link {
          background: transparent;
          border: none;
          color: #023b72;
        }
      }
    }

    .ant-table-content {
      padding: 0 20px;

      table {
        background: #ffffff;
        border-spacing: 0px 5px;

        .ant-table-thead {
          th {
            color: #5b5b5b;
            background: #ffffff !important;
            font: normal normal 800 15px SF Pro Display;
            border-bottom: none;

            &.ant-table-column-has-sorters:hover,
            &.ant-table-column-sort {
              background: #ffffff;
            }
          }
        }
      }
    }

    .ant-pagination {
      .ant-pagination-item {
        border: 1px solid #023b72;
        border-radius: 4px;

        a {
          color: #023b72;
        }

        &.ant-pagination-item-active {
          background: #023b72 0% 0% no-repeat padding-box;
          border-radius: 4px;
          border: none;

          a {
            color: #ffffff;
          }
        }
      }
    }

    .sub600-card-body {
      .ant-card-body {
        height: 700px !important;
        overflow: auto !important;
      }
    }

    .sub528-card-body {
      .ant-card-body {
        height: 528px !important;
        overflow: auto !important;
      }
    }

    .ant-card-head-title {
      color: #023b72;
      font-size: 22px;
    }
  }

  .long-term-button {
    &:disabled {
      background: grey !important;
    }
  }
}

.team_modal {
  .sub450-card-body {
    .ant-card-body {
      height: 450px;
      overflow-x: auto;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .no-padding {
    margin: 0px !important;
  }
  .col-bottom2 {
    border-left: 1px solid #eee;
    color: #fff;
    padding: 1px;
  }
}

.border-left {
  &::after {
    content: '';
    border-left: 1px solid #e2e2e2;
    position: absolute;
    top: 5px;
    left: 0;
    height: 55px;
    display: block;
  }
}

.membersInOut {
  .ant-card-head-title {
    font-size: 16px;
  }
}

.title {
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #023b72;
}

.feed-item-title {
  font-size: 21px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #023b72;
}

// .bf-container .bf-image img {
// width: 900px !important;

// }

.bf-container .bf-image img {
  max-width: 800 !important;
}

.bf-container {
  max-height: 500px;
}
