.add_obLungo_modal {
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}
