.semaphore-item {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}

.projects-dashboard {
  .semaphore-item {
    height: 40px;
    width: 40px;
  }
}

.greenColorIndicator {
  color: #ffffff;
  background-color: #4cbb17;
  @extend .semaphore-item;
}
.redColorIndicator {
  color: #ffffff;
  background-color: #ff1313;
  @extend .semaphore-item;
}
.yellowColorIndicator {
  color: #ffffff;
  background-color: #fbd311;
  @extend .semaphore-item;
}
.greyColorIndicator {
  color: #ffffff;
  background-color: #bdbdbd;
  @extend .semaphore-item;
}

.projectGreenColorIndicator {
  color: #ffffff;
  background-color: #4cbb17;
  @extend .semaphore-item;
}
.projectRedColorIndicator {
  color: #ffffff;
  background-color: #ff1313;
  @extend .semaphore-item;
}
.projectYellowColorIndicator {
  color: #ffffff;
  background-color: #fbd311;
  @extend .semaphore-item;
}
.projectGreyColorIndicator {
  color: #ffffff;
  background-color: #bdbdbd;
  @extend .semaphore-item;
}
