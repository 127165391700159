.risk-precaution {
  .addKpi {
    height: 35px;
    background: #ff8c1a 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: #ffffff;
    border: none;

    span {
      text-align: left;
      font: normal normal 400 15px SF Pro Display;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  .risk-precaution-block {
    font-size: 20px;
    margin: 15px 0;
    border-radius: 5px;
    span {
      color: #4c4c4c;
    }
  }

  .ant-list-item-meta-title {
    font-size: 20px !important;
    color: #023b72;
    letter-spacing: 0.5px;
  }

  .ant-list-item-meta-description {
    font-size: 16px !important;
    color: #023b72;
  }

  .list-items {
    padding: 10px;
  }

  .list-headers {
    width: 100% !important;
    border-bottom: 1px solid #e2e2e2;
  }

  .vertical-align {
    display: grid;
    align-items: center;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .anticon {
    &:hover {
      color: #023b72;
    }
  }

  .ant-card {
    padding: 0 30px;
    background: none;
    .ant-card-head {
      border-bottom: none;
      .ant-card-extra {
        margin-left: unset;
      }
    }
    .ant-card-body {
      padding: 20px;
      background: #ffffff;
      margin-top: 10px;
      height: unset;
    }
  }

  .countermeasures {
    .ant-list-items {
      background: none;
      .ant-list-item {
        //background: #f5f5f5;
        //margin: 10px;
        span {
          color: #4c4c4c;
          font-family: SF Pro Display;
        }
      }
    }
  }
}

.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 18px;
}

.add-critic-modal {
  .row-margin {
    margin: 0px !important;
  }

  .overflow {
    .ant-card-body {
      height: 480px;
      overflow-y: auto;
    }
  }

  .dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  [data-theme='dark'] .dynamic-delete-button {
    color: rgba(255, 255, 255, 0.45);
  }
  [data-theme='dark'] .dynamic-delete-button:hover {
    color: rgba(255, 255, 255, 0.65);
  }

  .button-wrapper-critic {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .left-align {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .sub-card-body > div:nth-child(2) {
    height: unset;
  }
}
