.upload-text {
  color: rgb(157, 157, 157);
  font-style: italic;
  font-size: 13px;
}

.upload-text-error {
  color: #f73333;
  font-style: italic;
  font-size: 13px;
}
.required-dot {
  color: #f73333;
}

.kpi-wrapper {
  .header-kpi {
    height: 95px;
    align-items: center;
    display: flex;
    background: transparent linear-gradient(267deg, #023b72 0%, #002255 100%) 0% 0% no-repeat padding-box;
    padding: 29px 89px 20px 100px;
    color: white;
    font: 24px SF Pro Display;
    row-gap: 0px;
  }
  .ant-table-filter-trigger-container {
    //background-color: #f1f1f1 !important;
  }

  .ant-card {
    background: none;
    margin: 0 25px;
    .ant-card-body {
      padding: 15px;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;

    .kpi-btn {
      height: 35px;
      background: #ff8c1a 0% 0% no-repeat padding-box;
      border-radius: 10px;
      opacity: 1;
      border: none;
      span {
        text-align: left;
        font: normal normal 400 15px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .button-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .table-height {
    tr {
      // height: 50px;
      padding: 0;
      margin: 0;

      td {
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
  }
  .ant-table-content {
    padding: 0 20px;
    table {
      background: #ffffff;
      border-spacing: 0px 5px;
      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;
          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;
      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;
        a {
          color: #ffffff;
        }
      }
    }
  }
}

.edit-kpi-wrapper {
  .xm-filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .ant-card-body {
    .main-container {
      .ant-form-item-control {
        .ant-select-selector,
        .ant-input,
        .ant-input-number-input-wrap {
          min-height: 32px;
          align-items: center;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-number,
            .ant-picker,
            .ant-input,
            .ant-select-selector {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
