.projectCard {
  border-radius: 19px;
  margin-bottom: 30px;
  //min-height: 450px;

  .ant-card-actions {
    li {
      margin: 4px 0px;
    }
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .ant-card-extra {
    padding: 0px;
    width: 100%;

    #statusSection {
      gap: 0px !important;
    }

    .card-extra-name {
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;
    }
  }

  .middle-data {
    display: flex;
    flex-direction: column;

    .second {
      //font: normal normal normal 16px/19px SF Pro Display;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 24px;
      letter-spacing: 0px;
      color: #202020;
      opacity: 1;
    }
  }

  .footer-expand {
    // width: 100%;
    // bottom: 10px;
    // right: 12px;
    // position: absolute;
    //margin-left: 15px!important;
    //margin-bottom: 10px;
    // border-top: 1px solid #dfdfdf;
    .ant-divider-horizontal {
      margin: 10px 0px;
    }
  }

  .footer-closed {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
    margin-bottom: 10px;
    // border-top: 1px solid #dfdfdf;
  }

  &:not(.expand) {
    .main {
      text-overflow: ellipsis;
      opacity: 1;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .greenColor {
    color: #16a34a;
  }

  .redColor {
    color: #dc2626;
  }

  .yellowColor {
    color: #eab308;
  }

  .greyColor {
    color: #e4e4e7;
  }

  .ant-card-body {
    padding: 0px;
  }

  .lower-section {
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    @media only screen and (max-width: 575px) {
      justify-content: space-between;
    }

    p {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: -0.015em;
      color: #585858;
      margin-bottom: 0px;
    }

    .green {
      background-color: #22c55e;
    }

    .yellow {
      background-color: #eab308;
    }

    .red {
      background-color: #ef4444;
    }

    .ant-avatar-sm {
      vertical-align: middle;
    }
  }

  .wrap-meta {
    display: flex;
    margin-bottom: 8px;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column;
    margin-left: 10px;

    .subtitle {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.015em;
      margin: 0px;
      color: #202020;
    }

    .description {
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.015em;
      //color: #000000;
      margin-bottom: 0px;
      color: #012c60;
      opacity: 1;
      margin-top: 10px;

      &.user {
        margin-bottom: 10px;
      }

      &.ellipsis {
        text-overflow: ellipsis;
        opacity: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .active-status {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.015em;
      text-decoration-line: underline;
      color: #f2ac25;
      margin-bottom: 0px;

      img {
        margin-right: 4px;
      }
    }

    .suspended-status {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.015em;
      text-decoration-line: underline;
      margin-bottom: 0px;
      color: #b63a32;

      img {
        margin-right: 4px;
      }
    }

    .draft-status {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.015em;
      text-decoration-line: underline;
      color: #686868;
      margin-bottom: 0px;

      img {
        margin-right: 4px;
      }
    }

    .completed-status {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.015em;
      text-decoration-line: underline;
      margin-bottom: 0px;
      color: #38b44c;

      img {
        margin-right: 4px;
      }
    }

    .delay {
      height: 10px;
      width: 10px;
      background-color: red;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  .date-divider {
    height: 50px;
    color: #dfdfdf;
    opacity: 1.5;
  }

  .ant-card-head {
    padding: 0px;
    border-radius: 19px 19px 0px 0px;
    min-height: 35px;
    margin-bottom: 0px;

    .green-color-bgr {
      background-color: #4cbb17;

      .ant-space-item {
        .greyColorIndicator {
          font-size: 20px !important;
          background-color: #4cbb17 !important;
        }
      }
    }

    .red-color-bgr {
      background-color: #ef4444;

      .ant-space-item {
        .greyColorIndicator {
          font-size: 20px !important;
          background-color: #ef4444 !important;
        }
      }
    }

    .yellow-color-bgr {
      background-color: #f59e0b;

      .ant-space-item {
        .greyColorIndicator {
          font-size: 20px !important;
          background-color: #f59e0b !important;
        }
      }
    }

    .header-border {
      border-right: 1px solid #ffffff;
    }

    .b-l {
      border-radius: 19px 0px 0px 0px;
    }

    .b-r {
      border-radius: 0px 19px 0px 0px;
    }
  }

  .ant-card-head-title {
    padding: 0px;
    min-height: auto;
    font-weight: bold;
    font-size: 14px;
    color: #0ea5e9;
  }

  .project-meta {
    margin: 10px 0px 15px 0px;

    .ant-card-meta-avatar {
      padding-right: 5px;
    }

    .ant-avatar {
      height: 40px;
      width: 40px;
    }

    .ant-card-meta-title {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.015em;
      color: black;
    }

    .ant-card-meta-description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.015em;
      color: #696969;
    }
  }

  .users {
    .ant-avatar {
      margin: 10px;
    }
  }

  .status-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .statusActive {
    background-color: #73cc8b !important;
  }

  .statusSospeso {
    background-color: #ffd81a !important;
  }

  .statusDraft {
    background-color: #e6e6e6 !important;
  }

  .statusConcluso {
    background-color: #ffffff !important;
  }

  .statusRevisionato {
    background-color: #ff8c1a !important;
  }
}

.pr10 {
  padding: 10px 5px 10px 5px;
}

.bold {
  font-weight: bold;
}

.leftBorder {
  border-left: 1px solid #9b9b9b;
}

.semaphoreSpace {
  width: '100%';
  text-align: center;
}

.currentWeek {
  // width: 13%;
  // height:11%;
  margin: 10px 0px;
  position: inherit;
  background-color: #1890ff;
  //right: -578%;
  width: 649%;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .currentWeek {
    width: 105%;
    margin: 18px;
    right: 5%;
  }
}

@media screen and (max-width: 664px) {
  .currentWeek {
    width: 108%;
    margin: 6px;
    right: 5%;
  }
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fixed-filter {
  position: sticky;
  top: 64px;
  z-index: 999;
  height: 95px;
  align-items: center;
  display: flex;
  background: transparent linear-gradient(267deg, #023b72 0%, #002255 100%) 0% 0% no-repeat padding-box;
  padding: 29px 89px 20px 100px;

  .fixed-filter-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    font-size: x-large;

    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        text-align: left;
        font: 24px SF Pro Display;
        letter-spacing: 0;
        color: #ffffff;
        opacity: 1;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: #d6f0fa !important;
        background-color: transparent;
        border: 1px solid #d6f0fa;
        border-radius: 4px;
      }

      .ant-select-arrow {
        color: #d6f0fa !important;
      }

      .filter-button {
        color: #abcdee;
      }

      .ant-btn-text {
        color: #ffffff;
      }
      .leftPanelButton {
        font-size: 22px;
      }
    }
  }

  .filter-burger {
    font-size: 22px;
    color: #ffffff;
    padding: 10px 17px 5px 10px;
  }
}

.fixed-grey-container {
  width: 100%;
  top: 159px;
  z-index: 999;
  height: 64px;
  background: rgba(190, 190, 190, 0.36);
  //opacity: 0.36;
  padding-left: 89px;
  padding-right: 89px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .fixed-grey-button {
    height: 34px;
    border: 1px solid #012b5f;
    border-radius: 11px;
    opacity: 1;
    margin-right: 13px;

    span {
      text-align: left;
      font: normal normal normal 14px/17px SF Pro Display;
      letter-spacing: 0px;
      color: #012b5f;
      opacity: 1;
    }
  }

  .right-label {
    text-align: left;
    font: normal normal normal 12px/14px SF Pro Display;
    letter-spacing: 0px;
    color: #989898;
    opacity: 1;

    .anticon {
      margin-right: 10px;
    }
  }
}
