#project-semaphores {
  .ant-space-item {
    margin-right: 0px !important;
  }

  .ant-col {
    padding-left: 12px !important;
    padding-right: 2px !important;
  }
}

.user-wrapper {
  padding: 10px;

  .ant-form-item-extra {
    margin-top: 30px;
  }

  .ant-form-item {
    display: flex;
    justify-content: space-between;
  }

  .table-row-consutivo {
    border-bottom: 2px solid #3e3e3e;
  }

  .xm-filter-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .card {
    margin-bottom: 10px;
  }

  .avatar {
    width: 120px !important;
    height: 120px !important;
  }

  .no-avatar {
    width: 80px !important;
    height: 80px !important;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .long-term-button {
      width: 160px;
      height: 45px;
      border-radius: 10px;
      border: none;

      &.save {
        background: #ff8c1a;
      }
      span {
        text-align: left;
        font: normal normal 400 18px SF Pro Display;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
      &:disabled {
        background: grey;
      }
    }
  }

  .mt20 {
    margin-top: 20px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .center {
    text-align: center !important;
  }

  .table-height {
    tr {
      padding: 0;
      margin: 0;

      td {
        border: none;
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          background: transparent;
          border: none;
          color: grey;
        }
      }

      .ant-pagination-item-link {
        background: transparent;
        border: none;
        color: #023b72;
      }
    }
  }

  .ant-table-content {
    padding: 0 20px;

    table {
      background: #ffffff;
      border-spacing: 0px 5px;

      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;

          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;

      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;

        a {
          color: #ffffff;
        }
      }
    }
  }

  .formInputs {
    width: 375px;
  }

  .resource {
    .ant-input-number {
      width: 100% !important;
      border: 1px solid #dcdcdc;
    }
    th {
      text-align: center !important;
    }
  }

  .main-container {
    //display: flex;
    //flex-direction: column;
    //margin-top: -10px;

    .ant-form-item-label {
      label {
        float: left;
      }
    }

    .ant-form-item-control {
      .ant-select-selector,
      .ant-input,
      .ant-input-number-input-wrap {
        min-height: 32px;
        align-items: center;
      }

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input {
            border: none;
          }
          .ant-input-number {
            width: 100%;
          }
        }
      }
    }
  }
}
