.projectSintesi {
  .fixed-option-container {
    width: 100%;
    top: 159px;
    z-index: 999;
    height: 64px;
    background: rgba(241, 241, 241, 0.36);
    //opacity: 0.36;
    padding-left: 26px;
    padding-right: 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .currentMonthLineIndicatorSintesi {
    border-left: 2px solid #d22323;
  }

  .fixed-filter-sintesi {
    height: 95px;
    padding: 29px 100px 20px 100px;
    color: #ffffff;
    background: transparent linear-gradient(267deg, #023b72 0%, #002255 100%) 0% 0% no-repeat padding-box;
    margin: 0;
    display: flex;
    align-items: center;

    .fixed-filter-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      font-size: x-large;

      .titleName {
        display: flex;
        justify-content: left !important;
        align-items: flex-end !important;
      }

      .filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .title {
          text-align: left;
          font: 24px SF Pro Display;
          letter-spacing: 0;
          color: #ffffff;
          opacity: 1;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          color: #d6f0fa !important;
          background-color: transparent;
          border: 1px solid #d6f0fa;
          border-radius: 4px;
        }

        .ant-select-arrow {
          color: #d6f0fa !important;
        }

        .filter-button {
          color: #abcdee;
        }

        .ant-btn-text {
          color: #ffffff;
        }
        .leftPanelButton {
          font-size: 22px;
        }
      }
    }
  }

  .table-sintesi {
    .sticky {
      background-color: #fff !important;
      z-index: 10;
    }

    .status-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .status {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .statusActive {
      background-color: #73cc8b !important;
    }

    .statusSospeso {
      background-color: #ffd81a !important;
    }

    .statusDraft {
      background-color: #d3d3d3 !important;
    }

    .statusConcluso {
      background-color: #8f8f8f !important;
    }

    .blueCell {
      background-color: #5f9fd5 !important;
    }

    tr {
      // height: 50px;
      padding: 0;
      margin: 0;

      td:last-child {
        text-align: center;
        padding: 2px;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          background: transparent;
          border: none;
          color: grey;
        }
      }

      .ant-pagination-item-link {
        background: transparent;
        border: none;
        color: #023b72;
      }
    }
  }
}
