.sub450-card-body {
  .ant-card-body {
    height: 450px;
    overflow-x: auto;
  }
}

.zoomSecHeight {
  height: 85vh !important;
  overflow: auto !important;

  .bf-container {
    height: 85vh !important;
    max-height: 100%;
  }

  .bf-content {
    height: 100%;
  }
}

.long-card {
  .ant-card-head-wrapper {
    height: 46px;
  }

  .ant-card-head-title {
    font-size: 17px;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      padding: 6px 0;
      font-size: 16px !important;
    }
  }
}

.cus-card {
  .ant-card-head-wrapper {
    height: 46px;
  }

  .ant-card-head-title {
    font-size: 17px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 2px 0;
      font-size: 16px !important;
    }
  }
}

.cus-collapse-title {
  .ant-collapse-header-text {
    font-size: 17px;
    color: #023b72;
    font-weight: 500;
  }
}

.active-obietivi-row {
  td {
    background: #dee1e5 !important;
  }
}

.bf-container {
  border: 1px solid #f0f0f0 !important;
}

.obietivo-long-wrapper {
  .obietivo-long-header {
    height: 95px;
    align-items: center;
    display: flex;
    background: transparent linear-gradient(267deg, #023b72 0%, #002255 100%) 0% 0% no-repeat padding-box;
    padding: 29px 89px 20px 100px;
    color: white;
    font: 24px SF Pro Display;
    margin-left: 0;
    grid-row-gap: 0px;
    row-gap: 0px;
  }

  .obietivo-button {
    height: 35px;
    background: #ff8c1a 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: none;

    span {
      text-align: left;
      font: normal normal 400 15px SF Pro Display;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }

  .ant-card {
    padding: 0 24px;
    background: none;
  }

  .center-text {
    text-align: center;
  }

  .ant-table-filter-trigger-container {
    //background-color: #f1f1f1 !important;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px dashed #cfcfcf;
    margin-bottom: 10px;
  }

  .table-height {
    tr {
      // height: 50px;
      padding: 0;
      margin: 0;

      td {
        padding: 9px 25px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }
  }

  .ant-table-content {
    padding: 0 20px;

    table {
      background: #ffffff;
      border-spacing: 0px 5px;

      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;

          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    margin: 16px 20px;

    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;

      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;

        a {
          color: #ffffff;
        }
      }
    }
  }
}

.edit-obietivo-long-wrapper {
  .ant-col {
    padding: 8px;
  }

  .ant-form-item {
    margin-bottom: 3px;
  }

  .ant-form-item-label {
    padding: 2px !important;
  }

  .ant-form-item-control {
    padding: 2px !important;
  }

  //.table-height{
  //  .ant-table{
  //    min-height: 280px;
  //  }
  //  tr td {
  //    padding: 10px 10px;
  //  }
  //
  //}
  .table-height {
    tr {
      padding: 0;
      margin: 0;

      td {
        border: none;
        padding: 9px 8px;
        color: #323232;
        background: #f2f2f2;
        font: normal normal 500 14px SF Pro Display;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          background: transparent;
          border: none;
          color: grey;
        }
      }

      .ant-pagination-item-link {
        background: transparent;
        border: none;
        color: #023b72;
      }
    }
  }

  .ant-table-content {
    padding: 0 20px;

    table {
      background: #ffffff;
      border-spacing: 0px 5px;

      .ant-table-thead {
        th {
          color: #5b5b5b;
          background: #ffffff !important;
          font: normal normal 800 15px SF Pro Display;
          border-bottom: none;

          &.ant-table-column-has-sorters:hover,
          &.ant-table-column-sort {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-item {
      border: 1px solid #023b72;
      border-radius: 4px;

      a {
        color: #023b72;
      }

      &.ant-pagination-item-active {
        background: #023b72 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border: none;

        a {
          color: #ffffff;
        }
      }
    }
  }
}
